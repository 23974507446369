import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { graphql } from "gatsby"
import { useTranslation } from "gatsby-plugin-react-i18next"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Hero from "../components/hero"

const IndexPage = () => {
  const { t } = useTranslation()

  return (
    <Layout>
      <Seo title={t("pageTitlePrefix") + t("home.title")} />
      <Hero subtitle={t("home.subtitle")} />
      <div id="about" className="container">
        <div className="row py-5 mb-5 content">
          <div className="pt-4 col-sm-12 col-md-10 col-lg-7">
            <h2 className="py-2">{t("home.subtitle")}</h2>
            <p className="intro">{t("home.first_para")}</p>
            <p>{t("home.second_para")}</p>
            <p>{t("home.third_para")}</p>
          </div>
          <div className="mt-4 col-xs-12 col-md-4 col-lg-5 d-lg-block d-none">
            <div className="row">
              <StaticImage
                src="../assets/el-1.jpg"
                placeholder="blurred"
                className="about-us-img col-5"
                alt="Arkinet puidutööde meistrid"
                width={360}
              />
              <StaticImage
                src="../assets/el-2.png"
                placeholder="blurred"
                className="about-us-img mt-5 mx-0 col-5"
                alt="puidust trepp"
                width={360}
              />
            </div>
          </div>
        </div>
        <div className="row content">
          <div className="col-xs-12 col-sm-9 col-md-7 col-lg-6 col-xlg-5">
            <StaticImage
              src="../assets/mak-logo.png"
              placeholder="blurred"
              alt="Maaelu Arengukava logo ja Euroopa Liidu embleem"
              width={500}
            />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        language: { eq: $language }
        fileAbsolutePath: { ne: "/images/" }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
